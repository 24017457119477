import React from "react"
import Seo from "../components/seo"
import HeroSection from "../components/pages/AccountantsAndBookKeepers/HeroSection"
import ProblemsSection from "../components/common/ProblemsSection"
import SolutionsSection from "../components/common/SolutionsSection"
import RoiCalculatorSection from "../components/pages/AccountantsAndBookKeepers/RoiCalculatorSection"
import TestimonialsSection from "../components/common/TestimonialsSection"
import HappyCustomerSection from "../components/common/HappyCustomerSection"
import FeaturesSection from "../components/pages/AccountantsAndBookKeepers/FeaturesSection"
import CaseStudySection from "../components/common/CaseStudySection"
import BusinessModelsWithFormSection from "../components/common/BusinessModelsWithFormSection"
import MobileAppLinksSection from "../components/common/MobileAppLinksSection"
import SecurityCardsSection from "../components/common/SecurityCardsSection"
import Layout from "../components/layout"
import {
  businessModelsWithFormSection,
  caseStudiesSection,
  faqs,
  featuresSection,
  happyCustomerSection,
  heroSection,
  mobileAppLinksSection,
  problemSection,
  solutionsSection,
  testimonialSection,
} from "../Content/AccountantsAndBookkeepers"
import FaqsSection from "../components/common/FaqsSection"

const AccountantsAndBookKeepers = () => {
  return (
    <Layout>
      <Seo
        title="Accountants & Bookkeepers"
        description={`Streamline your accounting and bookkeeping with Synkli Practice. Manage client files, connect with Synkli software, and boost your business potential effortlessly.`}
      />

      <HeroSection
        title={heroSection.title}
        description={heroSection.description}
        btns={heroSection.btns}
        widget={heroSection.widget}
      />

      <ProblemsSection
        title={problemSection.title}
        description={problemSection.description}
        topProblems={problemSection.topProblems}
        bottomProblems={problemSection.bottomProblems}
      />

      <SolutionsSection
        title={solutionsSection.title}
        description={solutionsSection.description}
        firstColumnSolutions={solutionsSection.firstColumnSolutions}
        secondColumnSolutions={solutionsSection.secondColumnSolutions}
        thirdColumnSolutions={solutionsSection.thirdColumnSolutions}
      />

      <RoiCalculatorSection />

      <FeaturesSection
        title={featuresSection.title}
        features={featuresSection.features}
      />

      <TestimonialsSection
        title={testimonialSection.title}
        description={testimonialSection.description}
      />

      <CaseStudySection
        title={caseStudiesSection.title}
        description={caseStudiesSection.description}
      />

      <HappyCustomerSection
        subTitle={happyCustomerSection.subTitle}
        mainTitle={happyCustomerSection.mainTitle}
        title={happyCustomerSection.title}
        description={happyCustomerSection.description}
      />

      <BusinessModelsWithFormSection
        title={businessModelsWithFormSection.title}
        description={businessModelsWithFormSection.description}
        formSubmitButtonText={
          businessModelsWithFormSection.formSubmitButtonText
        }
      />

      <FaqsSection faqs={faqs} />

      <MobileAppLinksSection
        title={mobileAppLinksSection.title}
        description={mobileAppLinksSection.description}
      />

      <SecurityCardsSection />
    </Layout>
  )
}

export default AccountantsAndBookKeepers
