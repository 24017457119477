import React, { useState } from "react"
import PageSectionHeader from "../../common/PageSectionHeader"
import RoiCalculatorFieldWithRangeSlider from "../../molecules/RoiCalculatorFieldWithRangeSlider"
import Button from "../../atoms/Button"

const RoiCalculatorSection = () => {
  const [employees, setEmployees] = useState(0)
  const [revenue, setRevenue] = useState(0)
  const [salary, setSalary] = useState(0)
  return (
    <div className="bg-[#0A1E46] calculator-section">
      <div className="synkli-section--horizontal-space py-16 sm:py-20 mt-20 md:mt-24 mb-10">
        <PageSectionHeader
          subTitle={`ROI CALCULATOR`}
          subTitleEC={`text-white border-white`}
          title={`Calculate your firm’s ROI with Synkli`}
          titleEC={`text-white !max-w-[680px]`}
        />
        <div className="bg-white mt-12 rounded-[30px] flex flex-col md:flex-row max-w-[1300px] mx-auto">
          <div className="flex-[2]">
            <div className="py-10 px-9">
              <h3 className="font-poppins text-[26px] font-[600] mb-4 !leading-[1.2em] text-center">
                Calculate your return on investment
              </h3>
              <div className="flex flex-col gap-[25px]">
                <RoiCalculatorFieldWithRangeSlider
                  sliderId={`nouislider1`}
                  title="Number of Employees"
                  currentValue={employees}
                  onchangeValue={value => setEmployees(value)}
                  maxValue={500}
                  interval={100}
                />
                <RoiCalculatorFieldWithRangeSlider
                  sliderId={`nouislider2`}
                  title="Annual Business Revenue"
                  currentValue={revenue}
                  onchangeValue={value => setRevenue(value)}
                  maxValue={500}
                  interval={100}
                />
                <RoiCalculatorFieldWithRangeSlider
                  sliderId={`nouislider3`}
                  title="Average Employee Salary"
                  currentValue={salary}
                  onchangeValue={value => setSalary(value)}
                  maxValue={500}
                  interval={100}
                />
              </div>
            </div>
          </div>
          <div className="flex-[1] m-3 md:m-0 bg-[#0A1E46] rounded-[30px] border-2 border-[#B695F850] py-10 px-9">
            <h3 className="font-poppins text-[26px] font-[600] mb-4 !leading-[1.2em] text-white">
              Experience the Synkli Effect
            </h3>
            <p className="font-poppins text-md mb-4 md:mb-7 !leading-[1.2em] text-white">
              These findings were obtained through Synkli’s 2023 Firm Usage
              Survey.
            </p>
            <div className="mt-10 md:mt-20 mb-10 text-center">
              <h4 className="font-poppins text-5xl md:text-6xl text-white font-medium mb-6">
                $<span className="roi-amount">6,400</span>
              </h4>
              <p className="font-poppins text-sm mb-10 md:mb-14 !leading-[1.2em] text-white">
                Projected Cost Saving
              </p>
              <Button
                to={"https://partners.synkli.com.au/"}
                href={"https://partners.synkli.com.au/"}
                text="Get Pricing Plan"
                ec={"secondary-btn px-8 lg:px-[50px] inline-block"}
              />
            </div>
            <p className="font-poppins text-md !leading-[1.2em] text-white">
              No commitment. You can cancel your subscription plan anytime.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RoiCalculatorSection
