import React from "react"
import PageHeroSection from "../../common/PageHeroSection"

const HeroSection = ({title, description, btns, widget}) => {
  return (
    <div className="synkli-section--horizontal-space pt-10 pb-10">
      <PageHeroSection
        title={title}
        titleEC={`max-w-[900px] lg:max-w-[1380px]`}
        description={description}
        descriptionEC={`max-w-[1250px]`}
        btns={btns}
      />
      <div className="text-center mt-14 max-w-[1230px] mx-auto px-6">
        {widget}
      </div>
    </div>
  )
}

export default HeroSection
