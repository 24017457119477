/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import nouislider from "nouislider"
import "nouislider/dist/nouislider.css"

function RangeSlider({
  sliderId,
  value,
  setValue,
  maxValue,
  interval,
  isShowtooltipValue,
}) {
  // const sliderId = `slider-${Math.random().toString(36).substr(2, 9)}` // Generate unique slider ID
  // const sliderId = `slider-${Math.floor(Math.random() * 100000) }` // Generate unique slider ID

  // Function to calculate pips values
  const calculatePipsValues = maxValue => {
    const values = []
    for (let i = 0; i <= maxValue; i += interval) {
      values.push(i)
    }
    return values
  }
  useEffect(() => {
    const element = document.getElementById(sliderId)
    if (element) {
      const slider = nouislider.create(element, {
        start: value,
        range: {
          min: 0,
          max: maxValue,
        },
        step: 1,
        tooltips: isShowtooltipValue,
        pips: {
          mode: "values",
          values: calculatePipsValues(maxValue),
          density: 20,
          format: {
            to: function (value) {
              return value.toFixed(0)
            },
          },
        },
        format: {
          to: function (value) {
            return value.toFixed(0)
          },
          from: function (value) {
            return parseInt(value)
          },
        },
        connect: [true, false], // Connect the handles only
      })

      slider.on("update", function (values, handle) {
        if (
          maxValue >= values[handle] &&
          values[handle] <= value &&
          value >= maxValue
        ) {
          setValue(parseInt(values[handle]))
        } else {
          if (value >= maxValue) {
          } else {
            setValue(parseInt(values[handle]))
          }
        }
      })
      return () => {
        slider.destroy()
      }
    }
  }, [value, sliderId, maxValue]) // Include maxValue in the dependency array

  return (
    <div id={sliderId} className="za-range-slider w-full mx-auto mt-4 mb-10" />
  )
}

export default RangeSlider
