import React from "react"
import RangeSlider from "../atoms/RangeSlider"

const RoiCalculatorFieldWithRangeSlider = ({
  sliderId,
  title,
  currentValue,
  onchangeValue,
  maxValue,
  interval,
}) => {
  return (
    <>
      <div className="py-5 px-6 border border-[#C4C2C2] rounded-[20px]">
        <div className="flex flex-col sm:flex-row gap-4 sm:gap-5 mt-3 sm:mt-0 justify-between items-center">
          <h4 className="font-poppins text-[18px] lg:text-[20px] md:text-[16px] font-[600] !leading-[1.2em] text-[#0A1E46] text-center sm:text-left">
            {title}
          </h4>
          <input
            type="text"
            className="w-[150px] lg:w-[230px] px-4 py-2 border border-[#0A1E46] rounded-[16px] outline-none"
            value={currentValue}
            onChange={e => {
              const value = e.target.value || 0
              // if(value)
              onchangeValue(value)
            }}
          />
        </div>
        <div>
          <RangeSlider
          sliderId={sliderId}
            value={currentValue}
            setValue={onchangeValue}
            maxValue={maxValue}
            interval={interval}
            isShowtooltipValue={false}
          />
        </div>
      </div>
    </>
  )
}

export default RoiCalculatorFieldWithRangeSlider
